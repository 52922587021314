import AppBorder from '../components/AppBorder'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import React from 'react'
import SEO from '../components/Seo'
import styled from 'styled-components'

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  grid-gap: 1em;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }
`

const GalleryItem = styled.div`
  position: relative;
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 99;
  bottom: 0;
  padding: 1em 0;
  position: absolute;
  color: white;
  text-align: center;
`

function convertToDays (timestamp) {
  const days = Math.floor((Date.now() / 1000 - timestamp) / 3600 / 24)

  if (days < 1) {
    return 'today'
  }

  return `${days} day${days > 1 ? 's' : ''} ago`
}

export default function PhotographyPage ({ data: { allInstaNode } }) {
  return (
    <Layout>
      <SEO title='Photography - Daniel Swaine' />
      <AppBorder title='Photos' colour='#f1f1f1'>
        <Gallery>
          {allInstaNode.edges.map(insta =>
            <GalleryItem key={insta.node.id}>
              <Overlay>
                Posted {convertToDays(insta.node.timestamp)}
              </Overlay>
              <Img fluid={insta.node.localFile.childImageSharp.fluid} />
            </GalleryItem>
          )}
        </Gallery>
      </AppBorder>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allInstaNode(sort: {order: DESC, fields: timestamp}) {
      edges {
        node {
          caption
          timestamp
          id
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
